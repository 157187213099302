import { gql } from '@apollo/client';

export const GET_EVENT_INFO = gql`
  fragment eventInfo on Event {
    eventClassification
    eventStartDateTimeISO
    id
    name
    image(size: "square80") {
      src
      width
      height
    }
    typeId
  }
`;

export const GET_DIVISION_INFO = gql`
  fragment divisionInfo on Division {
    gender
    genderId
    id
    maximumAge
    minimumAge
    name
    typeId
  }
`;


export const DIVISION_NAME = gql`
  fragment divisionName on Division {
    name
  }
`;


export const SCORING_FRAGMENT = gql`
  fragment eventParticipationRecords on ScoringInformation {
    EventParticipationRecord {
      participantFirstName
    }
  }
`;

// REUSED SCORING FRAGMENTS

export const CONTACT_INFO_FRAGMENT = gql`
  fragment contactInformationFragment on Contact {
    emailAddress
    id
    name
    phoneNumber
    typeId
  }
`;

export const DIVISION_FRAGMENT = gql`
  fragment divisionFragment on Division {
    gender
    genderId
    id
    maximumAge
    minimumAge
    name
    typeId
  }
`;

export const EVENT_PARTICIPANT_RECORD_FRAGMENT = gql`
  fragment eventParticipationRecordFragment on EventParticipationRecord {
    division @client
    scores @client
    chip1
    chip2
    chip3
    chipTotal
    drive1
    drive2
    drive3
    driveTotal
    id
    overallTotal
    participantDivisionId
    participantFirstName
    participantId
    participantImage(size: "square80") {
        ...imageAttributesFragment
    }
    participantLastName
    putt1
    putt2
    putt3
    puttTotal
    typeId
    startTime
    groupNumber
    starterNumber
    eventParticipationStatus
  }
`;

export const EVENT_FRAGMENT = gql`
  fragment eventFragment on Event {
    contactInformation {
      ...contactInformationFragment
    }
    eventClassification
    eventStartDateTimeISO
    id
    image(size: "square80") {
        ...imageAttributesFragment
    }
    name
    typeId
  }
`;

export const IMG_ALT_FRAGMENT = gql`
  fragment imageAlternateFormatFragment on ImageAltFormat {
    name
    src
    srcSets {
        ...imageSrcSetFragment
    }
    srcSetsRaw
  }
`;

export const IMG_ATTR_FRAGMENT = gql`
  fragment imageAttributesFragment on ImageAttributes {
    src
    width
    height
    srcSets{
        ...imageSrcSetFragment
    }
    srcSetsRaw
    altFormats {
        ...imageAlternateFormatFragment
    }
  }
`;

export const IMG_SRCSET_FRAGMENT = gql`
  fragment imageSrcSetFragment on ImageSrcSet {
    size
    src
  }
`;

export const KEY_VALUE_PAIR_FRAGMENT = gql`
  fragment keyValuePairFragment on KeyValuePair {
    key
    value
  }
`;

export const LINK_FRAGMENT = gql`
  fragment linkFragment on Link {
    href
    body
  }
`;


export const PARTICIPANT_INPUT = gql`
  fragment eventParticipationRecordInputFragment on EventParticipationRecord {
    chip1
    chip2
    chip3
    chipTotal
    drive1
    drive2
    drive3
    driveTotal
    putt1
    putt2
    putt3
    puttTotal
    id
    overallTotal
    participantId
    eventParticipationStatus
  }
`;
