const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const convertISOTime = (isoTime) => {
  let tDate = new Date(isoTime);
    const dateArray = tDate
      .toLocaleTimeString('en-US')
      .split(/:| /);
    let hour = dateArray[0];
    let minute = dateArray[1];
    let period = dateArray[3];
    return `${hour}:${minute} ${period}`;
}

export const convertISODate = (iso) => {
  let tDate = new Date(iso);
  return `${months[tDate.getMonth()]} ${tDate.getDate()}, ${tDate.getFullYear()}`;
}

export const throttle = (interval, throttledFunction) => {
  if (interval <= 0) {
    return throttledFunction
  }

  let lastTrigger = 0
  let timeout
  let lastArguments

  return function () {
    lastArguments = arguments

    // Already scheduled to run.
    if (timeout) {
      return
    }

    let context = this
    let now = +Date.now()
    let delay = interval - now + lastTrigger

    // Waited long enough so execute.
    if (delay <= 0) {
      lastTrigger = now
      throttledFunction.apply(context, lastArguments)

      // Schedule for later.
    } else {
      timeout = setTimeout(function () {
        lastTrigger = now
        timeout = null
        throttledFunction.apply(context, lastArguments)
      }, delay)
    }
  }
}
