import React from 'react'
import HelpLink from '../components/HelpLink';
import styles from './FinalScores.module.scss';

export default function FinalScores(props) {
  const { chipTotal, driveTotal, puttTotal, overallTotal } = props.data;
  return (
    <div className={styles.wrapper}>
      <ul className={`${styles.list} smallIcons`}>
        <li className={styles.header}><div className='skillicon all'></div><h2>Combined Skills</h2><HelpLink pullright={true} /></li>
        <li>
          <div className={styles.label}><div className='skillicon drive'></div>Drive Total</div>
          <input type='text' readOnly value={driveTotal || '-'} />
        </li>
        <li>
          <div className={styles.label}><div className='skillicon chip'></div>Chip Total</div>
          <input type='text' readOnly value={chipTotal || '-'} />
        </li>
        <li>
          <div className={styles.label}><div className='skillicon putt'></div>Putt Total</div>
          <input type='text' readOnly value={puttTotal || '-'} />
        </li>
        <li>
          <div className={styles.label}>Final Score</div>
          <input type='text' readOnly value={overallTotal || '-'} />
        </li>
      </ul>
    </div>
  )
}
