import React, { useEffect, useState, useCallback } from 'react'
import { useApolloClient, useQuery} from '@apollo/client';
import { IOS_PROMPT_SUPPRESS } from '../constants/queries';
import styles from './AddToHomeScreenPrompt.module.scss';
import iosicon from '../assets/icons/share-icon.svg';

export default function AddToHomeScreenPrompt() {
  const client = useApolloClient();
  const [deferredPrompt, updateDeferredPrompt] = useState(null);
  const [showAndroidPrompt, updateShowAndroidPrompt] = useState(false);
  const [showIosPrompt, updateShowIosPrompt] = useState(false);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const { data } = useQuery(IOS_PROMPT_SUPPRESS);
  // Detect iOS phone
  const isIosIPhone = useCallback(
    () => {
      return /iphone|ipod/.test( userAgent );
    },
    [userAgent],
  )

  // Detect iOS ipad
  const isIosIPad = useCallback(
    () => {
      return !!(/ipad/.test( userAgent )
      || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined"));
    },
    [userAgent],
  )

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  useEffect(() => {
    // Checks if should display ios prompt:
    if ((isIosIPhone() || isIosIPad()) && !isInStandaloneMode()) {
      updateShowIosPrompt(true)
      document.documentElement.style.setProperty('--promptSpace', '60px');
    }
    
    // check for android prompt install
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      updateDeferredPrompt(e)
      // Update UI notify the user they can install the PWA
      showInstallPrompt();
    });
  }, [isIosIPad, isIosIPhone])

  const showInstallPrompt = () => {
    updateShowAndroidPrompt(true);
    document.documentElement.style.setProperty('--promptSpace', '80px');
  }

  const onPromptButtonClick = () => {
    updateShowAndroidPrompt(false);
    document.documentElement.style.setProperty('--promptSpace', '0px');
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  const onCloseButtonClick = () => {
    updateShowAndroidPrompt(false);
    document.documentElement.style.setProperty('--promptSpace', '0px');
  }

  const onCloseIosButtonClick = () => {
    updateShowIosPrompt(false);
    document.documentElement.style.setProperty('--promptSpace', '0px');
    client.writeQuery({
      query: IOS_PROMPT_SUPPRESS,
      data: {
        iosPromptSuppress: true
      }
    });
  }
  
  return (
    <>
    <div className={`${styles.ath} ${showAndroidPrompt ? styles.show : styles.hide}`}>
      <button onClick={onPromptButtonClick}>Install DCP Scoring on your Device</button>
      <button onClick={onCloseButtonClick} className={styles.closebtn}>x</button>
    </div>
    <div className={`${styles.athios} ${isIosIPhone() ? styles.iphone : styles.ipad} ${showIosPrompt && !data.iosPromptSuppress ? styles.show : styles.hide}`}>
      <p>Add this webapp to your iPhone.<br />Click <img className={styles.iosicon} src={iosicon} alt='Share Icon' /> and choose 'Add to Homescreen'.</p>
      <button onClick={onCloseIosButtonClick} className={styles.closebtn}>x</button>
    </div>
    </>
  )
}
