import React, {useEffect, useCallback} from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { APP_STATUS, CURRENT_EVENT_ID, UPDATED_EPRS, INITIAL_EPRS } from '../constants/queries';
import { UPDATE_SCORING_INFORMATION } from '../constants/mutations';
import { isAppOnline } from '../cache';
  
export const checkOnlineStatus = async () => {
  let cachebust = Math.round(new Date().getTime() / 1000);
  try {
    const online = await fetch("/assets/pixel.png?cb="+cachebust);
    isAppOnline(true);
    return online.status >= 200 && online.status < 305; // either true or false
  } catch (err) {
    isAppOnline(false);
    return false; // definitely offline
  }
};

export default function UpdateUtilsContainer(props) { 
  const history = useHistory();
  const client = useApolloClient();
  const { data:updatedEPRData } = useQuery(UPDATED_EPRS);
  const [ updateScoringInfo ] = useMutation(
    UPDATE_SCORING_INFORMATION, { 
    update(cache, {data: dataReturn}) {
      cache.writeQuery({ query: APP_STATUS, data: {localUpdates: false, isUpdating: false, isErrored: false} })
      cache.writeQuery({ query: INITIAL_EPRS, data: {intitialEPRs: dataReturn.ScoringInformationMutation.result.eventParticipationRecords} });
      cache.writeQuery({ query: UPDATED_EPRS, data: {eprInputs: []} })
    },
    onError(cache, {data: dataReturn}) {
      client.writeQuery({ query: APP_STATUS, data: {isUpdating: true, isErrored: true, errorMessage: dataReturn.ScoringInformationMutation.message} });
      history.push('/error');
    }} 
  ); 
  const { data } = useQuery(APP_STATUS);

  const { data:eventdata } = useQuery(
    CURRENT_EVENT_ID,
    {
      fetchPolicy: 'cache-only'
    }
  );

  const updateDB = useCallback(() => {
    // we first do a test to see if there are actually any updated EPRs - this is a failsafe because
    // due to the useEffect callback and the dependencies for memoization a change may be detected when
    // the epr list is cleared; but there is no DB update needed for that scenario

    if (updatedEPRData.eprInputs.length < 1) return
    client.writeQuery({ query: APP_STATUS, data: {isUpdating: true, isErrored: false} });
    // only submit the eprs in the list of updated ids
    let updateObj = {
      id: eventdata.eventId,
      scoringInformation: {
        eventParticipationRecords: updatedEPRData.eprInputs
      }
    }
    updateScoringInfo({variables: updateObj})
  }, [eventdata.eventId, updateScoringInfo, updatedEPRData, client])

  const startPollingConnection = useCallback(() => {
    // console.log('startPollingConnection')
    let poll = setInterval(async () => {
        let isonline = await checkOnlineStatus();
        if (isonline) clearInterval(poll);
    }, 5000); // try every 30 seconds
  }, [])

  useEffect(() => {
    const checkAndUpdate = async () => {
      let isonline = await checkOnlineStatus();
      // check online status on first load
      if (data.localUpdates && isonline) {
        updateDB();
      } else if (data.localUpdates && !isonline) {
        // not online but has updates, start polling for connectivity.
        startPollingConnection();
      }
    }
    checkAndUpdate();
  }, [data.isOnline, data.localUpdates, updateDB, startPollingConnection])

  return (
    <>
    {props.children}
    </>
  );
}
