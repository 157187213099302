import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useApolloClient } from '@apollo/client';
import { APP_STATUS } from '../constants/queries';
import fallbacklogo from '../assets/icons/dcp-logo.svg';
import styles from './LoadingError.module.scss';

export default function LoadingError(props) {
  const history = useHistory();
  const client = useApolloClient();
  const { data:appStatus } = useQuery(APP_STATUS);

  const reload = () => {
    history.push('/');
  }

  const login = () => {
    client.resetStore();
    localStorage.removeItem('token');
    history.push('/');
  }

  return (
    <div className='content-wrapper content-pad'>
      <div className={styles.wrapper}>
        <img className={styles.logo} alt='logo' src={fallbacklogo} />
        <p className={`error-text ${styles.subhead}`}>{appStatus.errorMessage}</p>
        <button onClick={reload}>Reload App</button> <button onClick={login}>Login Again</button>
      </div>
    </div>
  )
}
