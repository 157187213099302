import { from, ApolloClient } from '@apollo/client';
import { HttpLink } from '@apollo/client';
import { RetryLink } from 'apollo-link-retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor } from 'apollo-cache-persist'
import createCache from './cache';
import { APP_STATUS, IOS_PROMPT_SUPPRESS } from './constants/queries';

const ROOT = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_ROOT_URL_DEVELOPMENT : process.env.REACT_APP_API_ROOT_URL;
const XSITE = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_XSITE_URL_DEVELOPMENT : process.env.REACT_APP_API_XSITE_URL;
const API_HOST = `${ROOT}/graphql/delivery/dcp/v1`;
const SCHEMA_VERSION = '1'
const SCHEMA_VERSION_KEY = 'apollo-schema-version'

const getApolloClient = async () => {
  const cache = createCache()
  // want these local vars to always reset on init of app
  cache.writeQuery({ query: APP_STATUS, data: {isErrored: false, isOnline: true} });
  cache.writeQuery({query: IOS_PROMPT_SUPPRESS, data: {iosPromptSuppress: false} });

  const httpLink = new HttpLink({ 
    uri: API_HOST,
    headers: {
      'X-Site': XSITE
    }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    if (token !== '' && token !== null) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        }
      }
    } else {
      return {
        headers: {
          ...headers
        }
      }
    }
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      cache.writeQuery({ query: APP_STATUS, data: {isErrored: true, errorMessage: networkError} })
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error
    }
  });

  const additiveLink = from([
    errorLink,
    retryLink,
    authLink,
    httpLink
  ]);


  const persistor = new CachePersistor({
    cache,
    storage: window.localStorage,
  })

  const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY)
  
  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore()
  } else {
    await persistor.purge()
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return new ApolloClient({ 
    link: additiveLink, 
    cache
  })
}

export default getApolloClient
