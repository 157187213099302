import React from 'react'
import styles from './Splash.module.scss';
import logo from '../assets/icons/dcp-logo-whitetext.svg';

export default function Splash() {
  return (
    <div className={styles.splash}>
      <img alt='splash' src={logo} />
    </div>
  )
}
