import React from 'react'
import { Link } from 'react-router-dom'
import closeicon from '../assets/icons/x-icon.svg';
import styles from './About.module.scss';

export default function About() {
  return (
    <div className='content-wrapper'>
      <div className={styles.about}>
        <Link className={styles.closeBtn} to='/home'><img src={closeicon} alt='close icon' /><span className='sr-only'>Close</span></Link>
        <h1 className='headline1'>About this App</h1>
        <p>
          This progressive web app is distributed by Drive, Chip, Putt for use by scoring administrators.<br />
        </p>
        <p>
          ©2021 DRIVE CHIP & PUTT. ALL RIGHTS RESERVED <br />
          <a href='https://www.drivechipandputt.com/privacy' rel="noopener noreferrer" target='_blank'>PRIVACY POLICY</a>•
          <a href='https://www.drivechipandputt.com/terms' rel="noopener noreferrer" target='_blank'>TERMS</a>
        </p>
      </div>
    </div>
  )
}
