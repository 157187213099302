import React, { useState, useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useQuery, useApolloClient } from '@apollo/client';
import { PARTICIPANT_RECORD_WITH_DIVISION, APP_STATUS, UPDATED_EPRS } from '../constants/queries';
import { EVENT_PARTICIPANT_RECORD_FRAGMENT } from '../constants/fragments';
import { convertISOTime } from '../constants/utils';
import { checkOnlineStatus } from '../containers/UpdateUtilsContainer';
import ToggleButton from '../components/ToggleButton';
import SkillHeader from '../components/SkillHeader';
import ParticipantHeader from '../components/ParticipantHeader';
import ParticipantButton from '../components/ParticipantButton';
import SkillScoresForm from '../components/SkillScoresForm';
import FinalScores from '../components/FinalScores';
import styles from './Participant.module.scss';

export default function Participant(props) {
  const client = useApolloClient();
  const [showSkillsScoring, setShowSkillsScoring] = useState(false);
  const [unsavedSkillScoreUpdates, setUnsavedSkillScoreUpdates] = useState(false);
  const [unsavedStatusUpdates, setUnsavedStatusUpdates] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentSkillScores, setCurrentSkillScores] = useState({});
  const skill = props.match.params.skillname || 'all'
  const id = props.match.params.id
  const { data:updatedEPRInputs } = useQuery(UPDATED_EPRS)
  const { data, loading } = useQuery(
    PARTICIPANT_RECORD_WITH_DIVISION,
    {
      variables: {
      'id': id
    },
    fetchPolicy: 'cache-only'
  });

  useEffect(() => {
    setCurrentStatus(data.participant.eventParticipationStatus)
    setCurrentSkillScores({...data.participant.scores})
  }, [data.participant])
 
  
  if (loading) return;

  const { participant, divisionParticipants } = data;
  const index = divisionParticipants.findIndex(participant => participant.id === id);

  const getParticipantData = (index) => {
    if (divisionParticipants[index] === undefined) return {};
    return {
      name: `${divisionParticipants[index].participantFirstName} ${divisionParticipants[index].participantLastName}`,
      startTime: divisionParticipants[index].startTime,
      id: divisionParticipants[index].id
    };
  }
  
  const participantButton = ($index, $label, $dir) => {
    let { name, startTime, id } = getParticipantData($index);
    return (
      <ParticipantButton disabled={unsavedSkillScoreUpdates || unsavedStatusUpdates} path={`/participant/${skill}/${id}`} direction={$dir} label={$label} participantName={name} startTime={convertISOTime(startTime)} />
    )
  }

  const onSkillsUpdateSubmit = (e) => {
    e.preventDefault();
    if (currentStatus !== participant.eventParticipationStatus) {
      client.writeFragment({
        id: 'EventParticipationRecord:'+id,
        fragment: EVENT_PARTICIPANT_RECORD_FRAGMENT,
        data: {
          eventParticipationStatus: currentStatus
        }
      })
    }
    client.writeFragment({
      id: 'EventParticipationRecord:'+id,
      fragment: EVENT_PARTICIPANT_RECORD_FRAGMENT,
      data: {...currentSkillScores, overallTotal: currentSkillScores.chipTotal + currentSkillScores.driveTotal + currentSkillScores.puttTotal}
    })
    // when a local update is made, 
    // and compile the deltas into a participant 'input' which is what the mutation expects,
    // combine it with the other existing delta participant Inputs which are saved (if the user is offline)
    // and then set the localUpdates flag to true
    // this is done so that only the participants with updates are sent with the update mutation
    // and we set it this way because the updating to the actual DB/CMS is handled in 'UpdateUtilsContainer' since it has logic for handling offline, etc.
    client.writeQuery({ query: UPDATED_EPRS, data: {eprInputs: compileDeltaParticipantInput()} })
    client.writeQuery({ query: APP_STATUS, data: {localUpdates: true} })

    setUnsavedSkillScoreUpdates(false);
    setUnsavedStatusUpdates(false);
  }

  const compileDeltaParticipantInput = () => {
    let mutationInput = {
      id: id,
      participantId: data.participant.participantId
    }
    for (let score in currentSkillScores) {
      if (data.originalParticipant[score] !== currentSkillScores[score]) {
        // this is a delta, add to ParticipantInput which will be sent w/mutation
        mutationInput[score] = currentSkillScores[score]
      }
    }

    if (data.originalParticipant.eventParticipationStatus !== currentStatus) {
      mutationInput.eventParticipationStatus = currentStatus
    }
    
    return [...updatedEPRInputs.eprInputs.filter(p => p.id !== id), {...mutationInput}]
  }

  const trackSkillScoreUpdates = (skillobj) => {
    let updates = false;
    let cscores = {...currentSkillScores};
    for (var prop in skillobj) {
      cscores[prop] = skillobj[prop];
      let storedScore = participant[prop] === null ? -2 : participant[prop];
      if (Number(storedScore) !== Number(skillobj[prop])) {
        // there are unsaved updates.
        updates = true;        
      }
    }
    checkOnlineStatus();
    setCurrentSkillScores({...cscores});
    setUnsavedSkillScoreUpdates(updates);
  }

  const trackStatusUpdates = (changedStatus) => {
    setCurrentStatus(changedStatus);
    let update = false;
    if (participant.eventParticipationStatus !== changedStatus) {
      update = true;
    }
    checkOnlineStatus();
    setUnsavedStatusUpdates(update);
  }

  const getPanelContent = () => {
    if (showSkillsScoring) {
      return (
        <CSSTransition key='scoring' timeout={300} classNames='opacity-transition' component={null}>
        <div className={`${styles.panel} ${styles.stickyButtonPad}`}>
          <SkillHeader skill='drive' label='Drive Skill' hideBack={true} isParticipant={true} showHelp={true} />
          <SkillScoresForm id={id} skill='drive' onUpdate={(skillobj) => trackSkillScoreUpdates(skillobj)} />
          <SkillHeader skill='chip' label='Chip Skill' hideBack={true} isParticipant={true} />
          <SkillScoresForm id={id} skill='chip' onUpdate={(skillobj) => trackSkillScoreUpdates(skillobj)} />
          <SkillHeader skill='putt' label='Putt Skill' hideBack={true} isParticipant={true} />
          <SkillScoresForm id={id} skill='putt' onUpdate={(skillobj) => trackSkillScoreUpdates(skillobj)} />
          <div className={`${styles.stickyButton} ${styles.saveButton}`}>
            <button disabled={!unsavedSkillScoreUpdates && !unsavedStatusUpdates} onClick={onSkillsUpdateSubmit} type='submit'>Save</button>
          </div>
        </div>
        </CSSTransition>
      )
    } else {
      return (
        <CSSTransition key='total' timeout={300} classNames='opacity-transition' component={null}>
        <div className={styles.panel}>
          <FinalScores data={participant} />
          <div className={styles.saveButton}>
            <button disabled={!unsavedSkillScoreUpdates && !unsavedStatusUpdates} onClick={onSkillsUpdateSubmit} type='submit'>Save</button>
          </div>
        </div>
        </CSSTransition>
      )
    }
  }

  if (skill !== 'all') {
    return (
      <div className='content-wrapper content-top fake-popup-border'>
        <ParticipantHeader onStatusUpdate={trackStatusUpdates} disableBackButton={unsavedSkillScoreUpdates || unsavedStatusUpdates} backpath={`/skill/${skill}/${participant.participantDivisionId}`} data={participant} />
        <SkillHeader skill={skill} label={`${skill} Skill`} hideBack={true} isParticipant={true} showHelp={true} />
        <SkillScoresForm id={id} skill={skill} onUpdate={(skillobj) => trackSkillScoreUpdates(skillobj)}>
          <div className={styles.utilNav}>
            <div className={styles.participantButtons}>
              {participantButton(index - 1, 'Previous', '0')}{participantButton(index + 1, 'Up Next', '1')}
            </div>
            <div className={styles.saveButton}>
              <button disabled={!unsavedSkillScoreUpdates && !unsavedStatusUpdates} onClick={onSkillsUpdateSubmit} type='submit'>Save</button>
            </div>
          </div>
        </SkillScoresForm>
      </div>
    )
  } else {
    return (
      <div className='content-wrapper content-top fake-popup-border'>
        <ParticipantHeader onStatusUpdate={trackStatusUpdates} disableBackButton={unsavedSkillScoreUpdates || unsavedStatusUpdates} data={participant} />
        <div className={styles.panelwrapper}>
          <ToggleButton disabled={unsavedSkillScoreUpdates} labelone='Final Scores' labeltwo='Skill Scores' isChecked={showSkillsScoring} clickHandler={() => setShowSkillsScoring(!showSkillsScoring)} />
          <TransitionGroup>
            {getPanelContent()}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}
