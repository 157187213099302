import React from 'react'
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_DIVISION_INFO } from '../constants/fragments';
import { PARTICIPANTS_IN_EVENT, CURRENT_EVENT_ID, DIVISION_PARTICIPANTS } from '../constants/queries';
import { convertISOTime } from '../constants/utils';
import Header from '../components/Header';
import SkillHeader from '../components/SkillHeader';
import ParticipantListLink from '../components/ParticipantListLink';
import styles from './Division.module.scss';

export default function Division(props) {
  let participantList = [];
  const client = useApolloClient();
  const id = props.match.params.divisionid;
  const skill = props.match.params.skillname;
  const { name } = client.readFragment({id:'Division:'+id, fragment: GET_DIVISION_INFO});
  const { data } = useQuery(CURRENT_EVENT_ID);

  const { data:eventdata } = useQuery(
    PARTICIPANTS_IN_EVENT,
    {
      variables: {
        id: data.eventId,
      },
      fetchPolicy: 'cache-only'
    }
  );
  
  if (eventdata !== null) {
    participantList = eventdata.ScoringInformation.eventParticipationRecords.filter(participant => participant.participantDivisionId === id);
    participantList.sort((a, b) => a.starterNumber - b.starterNumber);
    client.writeQuery({
      query: DIVISION_PARTICIPANTS,
      data: {
        divisionParticipants: [...participantList],
      },
    });
  }

  return (
    <div>
      <Header />
      <div className="content-wrapper content-top">
        <SkillHeader backpath={`/skill/${skill}`} label={skill} skill={skill} division={name} />
          <ul className={styles.participantlist}>
            <li>
              <ul className={styles.participantHeaders}>
                <li>#</li>
                <li>Name</li>
                <li>Start</li>
                <li>Score</li>
              </ul>
            </li>
          {participantList.map((participant) => (
            <li key={participant.id}>
                <ul className={styles.participantResults}>
                  <li>{participant.starterNumber !== null ? participant.starterNumber : 'NA'}</li>
                  <li><ParticipantListLink path={`/participant/${skill}/${participant.id}`}><strong>{participant.participantFirstName} {participant.participantLastName}</strong></ParticipantListLink></li>
                  <li>{convertISOTime(participant.startTime) || 'NA'}</li>
                  <li>{participant[`${skill}Total`] === null ? '-' : participant[`${skill}Total`]}</li>
                </ul>
            </li>
          ))}
          </ul>
      </div>
    </div>
  )
}
