import React, { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { throttle } from '../constants/utils';
import About from '../views/About';
import Login from '../views/Login';
import Home from '../views/Home';
import Skill from '../views/Skill';
import Division from '../views/Division';
import Participant from '../views/Participant';
import Roster from '../views/Roster';
import LoggedOut from '../views/LoggedOut';
import AddToHomeScreenPrompt from '../components/AddToHomeScreenPrompt';
import UpdateUtilsContainer from '../containers/UpdateUtilsContainer';
// temp
import Loading from '../views/Loading';
import Splash from '../views/Splash';
import LoadingError from '../views/LoadingError';

export default function ViewContainer() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const setScrollYPosition = () => {
      document.documentElement.style.setProperty('--windowScrollYpx', window.scrollY+'px');
    }
    window.addEventListener('scroll', throttle(550, setScrollYPosition));
    return () => {
      window.removeEventListener('scroll', throttle);
    }
  })

  return (
    <>
    <UpdateUtilsContainer>
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={300} classNames='route'>
          <Switch location={history.location}>
            <Route exact path="/" component={Login} />
            <Route exact path="/loading" component={Loading} />
            <Route exact path="/splash" component={Splash} />
            <Route exact path="/error" component={LoadingError} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/skill/:skillname" component={Skill} />
            <Route exact path="/skill/:skillname/:divisionid" component={Division} />
            <Route exact path="/roster" component={Roster} />
            <Route exact path="/about" component={About} />
            <Route exact path="/loggedout" component={LoggedOut} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={400} classNames='participant'>
          <Switch location={history.location}>
            <Route exact path="/participant/:skillname/:id" component={Participant} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </UpdateUtilsContainer>
    <AddToHomeScreenPrompt />
    </>
  )
}
