import React from 'react'
import { CSSTransition } from 'react-transition-group';
import styles from './ModalPrompt.module.scss';

export default function ModalPrompt(props) {  
  return (
    <CSSTransition
        in={props.show}
        timeout={400}
        classNames='modal'
      >
    <div className={`${styles.wrapper}`}>
      <div className={`inner-content ${styles.content}`}>
        <h1 className={styles.h1}>Are you sure?</h1>
        <p>You have unsaved updates.</p>
        <p> If you logout before these updates are synched, they will be lost. You may need to resume network connection in order for the synch to happen.</p>
        <button type='button' onClick={props.onLogout}>Yes, Logout</button>
        <button className='button-primary-inverse' type='button' onClick={props.onCancel}>No, Cancel</button>
      </div>
    </div>
    </CSSTransition>
  )
}
