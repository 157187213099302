import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client';
import styles from './SkillScoresForm.module.scss';
import { PARTICIPANT_RECORD_WITH_SCOREOPTIONS } from '../constants/queries';
import ScoreOptionSelect from './ScoreOptionSelect';

export default function SkillScoresForm(props) {
  const [ currentSkillAttempt1, updateCurrentSkillAttempt1 ] = useState(null);
  const [ currentSkillAttempt2, updateCurrentSkillAttempt2 ] = useState(null);
  const [ currentSkillAttempt3, updateCurrentSkillAttempt3 ] = useState(null);
  const [ currentSkillTotal, updateCurrentSkillTotal ] = useState(null);
  const noScore = '-1'
  const noScoreNum = -1
  const { skill, id } = props;
  const { data } = useQuery(
    PARTICIPANT_RECORD_WITH_SCOREOPTIONS,
    {
      variables: {
      'id': id
    },
    fetchPolicy: 'cache-only'
  });
  const { participant } = data;
  const options = data[skill];

  useEffect(() => {
    // set initial selected value on mount.
    updateCurrentSkillAttempt1(participant[`${skill}1`]);
    updateCurrentSkillAttempt2(participant[`${skill}2`]);
    updateCurrentSkillAttempt3(participant[`${skill}3`]);
    updateCurrentSkillTotal(participant[`${skill}Total`]);
  }, [participant, skill])

  const updateAttempt1 = (value) => {
    let total = addTotal(value, currentSkillAttempt2, currentSkillAttempt3);
    updateCurrentSkillAttempt1(value);
    updateCurrentSkillTotal(total);
    props.onUpdate({
      [`${skill}1`]: value,
      [`${skill}2`]: currentSkillAttempt2,
      [`${skill}3`]: currentSkillAttempt3,
      [`${skill}Total`]: total
    });
  }

  const updateAttempt2 = (value) => {
    let total = addTotal(value, currentSkillAttempt1, currentSkillAttempt3);
    updateCurrentSkillAttempt2(value);
    updateCurrentSkillTotal(total);
    props.onUpdate({
      [`${skill}1`]: currentSkillAttempt1,
      [`${skill}2`]: value,
      [`${skill}3`]: currentSkillAttempt3,
      [`${skill}Total`]: total
    });
  }

  const updateAttempt3 = (value) => {
    let total = addTotal(value, currentSkillAttempt2, currentSkillAttempt1);
    updateCurrentSkillAttempt3(value);
    updateCurrentSkillTotal(total);
    props.onUpdate({
      [`${skill}1`]: currentSkillAttempt1,
      [`${skill}2`]: currentSkillAttempt2,
      [`${skill}3`]: value,
      [`${skill}Total`]: total
    });
  }

  const addTotal = ($val1, $val2, $val3) => {
    // these could be null or a number
    // if all values are null, null the total score
    if ($val1 === null && $val2 === null && $val3 === null) {
      return null;
    }
    // if all values are '-1' (noScore) or -1 (noScoreNum), null the total score
    if (($val1 === noScore || $val1 === noScoreNum) && ($val2 === noScore || $val2 === noScoreNum) && ($val3 === noScore || $val3 === noScoreNum)) {
      return null;
    }

    let val1 = ($val1 === null || $val1 === noScore || $val1 === noScoreNum) ? 0 : $val1;
    let val2 = ($val2 === null || $val2 === noScore || $val2 === noScoreNum) ? 0 : $val2;
    let val3 = ($val3 === null || $val3 === noScore || $val3 === noScoreNum) ? 0 : $val3;
    return Number(val1) + Number(val2) + Number(val3); 
  }

  return (
    <form className={styles.form}>
      <label>
        <span>{skill} Attempt 1</span>
        <ScoreOptionSelect onUpdate={(score) => updateAttempt1(score === '-' ? null : score)} initialScore={currentSkillAttempt1} options={options} />
      </label>
      <label>
        <span>{skill} Attempt 2</span>
        <ScoreOptionSelect onUpdate={(score) => updateAttempt2(score === '-' ? null : score)} initialScore={currentSkillAttempt2} options={options} />
      </label>
      <label>
        <span>{skill} Attempt 3</span>
        <ScoreOptionSelect onUpdate={(score) => updateAttempt3(score === '-' ? null : score)} initialScore={currentSkillAttempt3} options={options} />
      </label>
      <label>
        <span>Total {skill} Score</span>
        <input className={styles.totalscoreinput} readOnly value={currentSkillTotal === null ? '-' : currentSkillTotal} type='text' />
      </label>
      {props.children}
    </form>
  )
}
