import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client';
import { CURRENT_EVENT_ID, SCORING_INFORMATION } from '../constants/queries';
import styles from './Skill.module.scss';
import Header from '../components/Header';
import HelpLink from '../components/HelpLink';
import SkillHeader from '../components/SkillHeader';
import Loading from './Loading';

export default function Skill(props) {
  const skill = props.match.params.skillname
  const { data } = useQuery(CURRENT_EVENT_ID);
  const { loading, error, data: eventdata } = useQuery(
    SCORING_INFORMATION, {
      variables: {
        'id': data.eventId
      },
      fetchPolicy: 'cache-only',
      errorPolicy: 'all'
    }
  );

  if (loading) { 
    return (
      <Loading />
    );
  }

  if (error) {  
    error.graphQLErrors.forEach(({message}) => { console.log(message)});
  }

  const divisions = eventdata.ScoringInformation.divisions;
  // const female = divisions.filter(division => division.gender === 'Female')
  // const male = divisions.filter(division => division.gender === 'Male')

  const female = divisions.filter(division => division.genderId === 'F')
  const male = divisions.filter(division => division.genderId === 'M')
  
  return (
    <div>
      <Header />
      <div className="content-wrapper content-top">
        <SkillHeader backpath='/home' label={skill} skill={skill} />
        <div className={styles.divisionwrapper}>
          <div className={styles.instructions}>
            <p>Select Division Below</p>
            <HelpLink />
          </div>
          <div className={styles.divisions}>
            <dl className={styles.genderDivision}>
              <dt className={styles.divisionHeader}>Boys</dt>
              {male.map((division) => (
                <dd key={division.id}>
                  <Link className='not-button' to={`${skill}/${division.id}`} >{division.minimumAge} - {division.maximumAge}</Link>
                </dd>
                )
              )}
            </dl>
            <dl className={styles.genderDivision}>
              <dt className={styles.divisionHeader}>Girls</dt>
              {female.map((division) => (
                <dd key={division.id}>
                  <Link className='not-button' to={`${skill}/${division.id}`} >{division.minimumAge} - {division.maximumAge}</Link>
                </dd>
                )
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
