import React from 'react'
import styles from './LoadingBar.module.scss';

export default function LoadingBar() {
  // const percent = `${props.percent}%`;{percent} 
  // const widthStyle = {width: percent}; style={widthStyle}
  return (
    <>
    <div className={styles.bar}>
      <div className={styles.loading}></div>
    </div>
    <div className={styles.message}>Loading</div>
    </>
  )
}
