import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { LOGIN, CURRENT_EVENT_ID, APP_STATUS, IOS_PROMPT_SUPPRESS } from '../constants/queries';
import { LOGIN_MUTATION } from '../constants/mutations';
import styles from './Login.module.scss';
import fallbacklogo from '../assets/icons/dcp-logo.svg';
import Splash from './Splash';
import Loading from './Loading';
import LoadingError from './LoadingError';

export default function Login() {
  const history = useHistory();
  const client = useApolloClient();
  client.writeQuery({query: IOS_PROMPT_SUPPRESS, data: {iosPromptSuppress: false} });
  
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [selectedEventId, updateEventId] = useState('');
  const [officialAccessCode, updateOfficialAccessCode] = useState('');
  const [officialFirstName, updateOfficialFirstName] = useState('');
  const [officialLastName, updateOfficialLastName] = useState('');

  const { loading, error, data } = useQuery(
    LOGIN, {
      variables: {
        'path': process.env.REACT_APP_API_PATH_VAR
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onError(cache) {
        client.writeQuery({ query: APP_STATUS, data: {isErrored: true, errorMessage: 'You must have internet connection to load the initial state of the app.'} });
        setIsLoggingIn(false);
        history.push('/error');
      }
    }
  );

  const [ sendLoginInfo, {error:mutationError} ] = useMutation(LOGIN_MUTATION, {
    update(cache, {data: returnData}) {
      if (returnData.LoginScreenMutation.status === 200) {
        localStorage.setItem('token', returnData.LoginScreenMutation.result);
        client.writeQuery({ query: APP_STATUS, data: {isLoggedIn: true, isErrored: false, isOnline: true} })
        client.writeQuery({ query: CURRENT_EVENT_ID, data: {eventId: selectedEventId} })
        history.push('/home');
      } else {
        client.writeQuery({ query: APP_STATUS, data: {isErrored: true, errorMessage: returnData.LoginScreenMutation.message} });
        history.push('/error');
      }
    },
    onError(cache) {
      client.writeQuery({ query: APP_STATUS, data: {isErrored: true, errorMessage: 'You must have internet connection to load the initial state of the app.'} });
      setIsLoggingIn(false);
      history.push('/error');
    }
  });


  const onFormSubmit = (e) => {
    e.preventDefault();
    let updateObj = {
      "path": process.env.REACT_APP_API_PATH_VAR,
      "loginInput": {
        "accessCode": officialAccessCode,
        "eventId": selectedEventId,
        "firstName": officialFirstName,
        "lastName": officialLastName
      }
    }
    setIsLoggingIn(true);
    sendLoginInfo({variables: updateObj})
  }

  if (loading) { 
    return (
      <div>
        <Splash />
      </div>
    );
  }

  if (error || mutationError) {
    client.writeQuery({ query: APP_STATUS, data: {isErrored: true, errorMessage: 'You must have internet connection to load the initial state of the App.'} });
    return (
      <div>
        <LoadingError />
      </div>
    )
  }

  if (isLoggingIn) { 
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const { description, logo, currentEvents } = data.LoginScreen;
  
  const loginForm = () => {
    if (currentEvents.length > 0) {
      return (
        <form onSubmit={onFormSubmit} className={styles.form}>
          <label className='required'>
            <span>Select Available Event</span>
            <select onChange={(e) => updateEventId(e.currentTarget.value)} required placeholder='Select an event'>
              <option value='' defaultValue>Select an Event</option>
              {currentEvents.map(event => (
                <option key={event.id} value={event.id}>{event.name}</option>
              ))}
            </select>
          </label>
          <label className='required'>
            <span>Access Code</span>
            <input onChange={(e) => updateOfficialAccessCode(e.currentTarget.value)} type='text' placeholder='Enter access code here' required name='temp' />
          </label>
          <label className={`${styles.halfsizeinput} required`}>
            <span>First Name</span>
            <input onChange={(e) => updateOfficialFirstName(e.currentTarget.value)} type='text' placeholder='John' required name='temp' />
          </label>
          <label className={`${styles.halfsizeinput} required`}>
            <span>Last Name</span>
            <input onChange={(e) => updateOfficialLastName(e.currentTarget.value)} type='text' placeholder='Smith' required name='temp' />
          </label>
          <button>Submit</button>
        </form>
      )
    } else {
      return (
        <p>There are no available events today.</p>
      )
    }
  }

  return (
    <div>
    <div className='content-wrapper content-pad'>
      <div className={styles.wrapper}>
        <img className={styles.logo} alt='logo' src={logo.src || fallbacklogo} />
        <h1 className={styles.h1}>Event Scoring Tool</h1>
        <p className={styles.subhead} dangerouslySetInnerHTML={{__html: description}}></p>
        {loginForm()}
      </div>
    </div>
    </div>
  )
}
