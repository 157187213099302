import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client';
import { APP_STATUS } from '../constants/queries';
import { convertISOTime } from '../constants/utils';
import ParticipantStatus from './ParticipantStatus';
import AppStatusIndicator from '../components/AppStatusIndicator';
import fpoimg from '../assets/no-image.png';
import styles from './ParticipantHeader.module.scss';

export default function ParticipantHeader(props) {
  const { participantImage, participantFirstName, participantLastName, startTime, eventParticipationStatus, division } = props.data;
  const history = useHistory();
  const { data } = useQuery(APP_STATUS);

  const onBack = () => {
    document.documentElement.style.setProperty('--participantTransitionTime', '150ms');
    document.documentElement.style.setProperty('--participantIn', 'translateX(0%)');
    document.documentElement.style.setProperty('--participantOut', 'translateX(0%)');
    document.documentElement.style.setProperty('--participantInOpacity', '1');
    document.documentElement.style.setProperty('--participantOutOpacity', '0');
    if (props.backpath) {
      history.push(props.backpath);
    } else {
      history.goBack();
    }
  }

  let imgsrc = data.isOnline ? participantImage.src : fpoimg;

  return (
    <div className={styles.wrapper}>
      <div className={styles.toputils}>
        <button disabled={props.disableBackButton} className={`${styles.backbutton} not-button`} onClick={onBack}>Back</button>
        <AppStatusIndicator />
      </div>
      <div className={styles.header}>
        <div className={styles.imagewrapper} style={{'backgroundImage': `url(${imgsrc})`}}></div>
        <div className={styles.details}>
          <h1 className={styles.h1}>{participantFirstName} {participantLastName}</h1>
          <h2 className={styles.h2}>{division}, Start time {convertISOTime(startTime)}</h2>
          <ParticipantStatus onUpdate={props.onStatusUpdate} currentStatus={eventParticipationStatus} />
        </div>
      </div>
    </div>
  )
}
