import React from 'react'
import styles from './Loading.module.scss';
import LoadingBar from '../components/LoadingBar';
import logo from '../assets/icons/dcp-logo.svg';

export default function Loading() {
  return (
    <div className="content-wrapper content-pad">
      <div className={styles.wrapper}>
        <img className={styles.logo} alt='logo' src={logo} />
        <h1 className={styles.h1}>Event Scoring Tool</h1>
        <p className={styles.subhead}>
        Welcome to Drive, Chip and Putt Scoring. We're loading.
        </p>
        <LoadingBar />
      </div>
    </div>
  )
}
