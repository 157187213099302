import React from 'react'
import { useQuery } from '@apollo/client';
import { APP_STATUS } from '../constants/queries';
import styles from './AppStatusIndicator.module.scss';

export default function AppStatusIndicator() {
  const { data } = useQuery(APP_STATUS);
  const cssOnlineClass = data.isOnline ? 'online' : 'offline';
  const cssUpdatingClass = data.isUpdating ? 'synching' : 'synched';
  const cssErrorClass = data.isErrored || (data.localUpdates && !data.isOnline) ? 'error' : 'success';

  const getMessage = () => {
    let message = 'Online';
    if ((data.isErrored && data.localUpdates) || (data.localUpdates && !data.isOnline)) {
      return (
        <>
        Unsynced Updates - <br /> No Connection
        </>
      )
    } else if (data.isUpdating) {
      message = 'Synching';
    } else {
      message = cssOnlineClass;
    }
    return message;
  }

  return (
    <div className={`${styles.base} ${styles[cssOnlineClass]} ${styles[cssUpdatingClass]} ${styles[cssErrorClass]}`}>
      <span className={styles.status}>{getMessage()}</span>
    </div>
  )
}
