import React from 'react'
import { useHistory } from 'react-router-dom'
import fallbacklogo from '../assets/icons/dcp-logo.svg';
import styles from './LoadingError.module.scss';

export default function LoggedOut(props) {
  const history = useHistory();

  const reload = () => {
    history.push('/');
  }

  return (
    <div className='content-wrapper content-pad'>
      <div className={styles.wrapper}>
        <img className={styles.logo} alt='logo' src={fallbacklogo} />
        <p className={`error-text ${styles.subhead}`}>You are logged out. Please log in again to view the Scoring options.</p>
        <button onClick={reload}>Go to Login</button>
      </div>
    </div>
  )
}
