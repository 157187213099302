import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import AppStatusIndicator from '../components/AppStatusIndicator';
import { checkOnlineStatus } from '../containers/UpdateUtilsContainer';
import styles from './Header.module.scss';
import logo from '../assets/dcp-horizontal-logo.svg';

export default function Header() {
  const history = useHistory();
  const mountedRef = useRef(true)

  const onHome = async (e) => {
    e.preventDefault();
    checkOnlineStatus().then(response => {
      if (mountedRef.current) {
        history.push('/home');
      }
    });
  }

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <div className={styles.headerwrapper}>
      <div className={styles.header}>
        <a onClick={onHome} href='/home'><img className={styles.logo} src={logo} alt="drive, chip, putt" /></a>
        <div className={styles.utils}>
          <AppStatusIndicator />
        </div>
      </div>
    </div>
  )
}
