import { gql } from '@apollo/client';
import { CONTACT_INFO_FRAGMENT, 
  DIVISION_FRAGMENT, 
  EVENT_PARTICIPANT_RECORD_FRAGMENT, 
  EVENT_FRAGMENT, 
  IMG_ALT_FRAGMENT, 
  IMG_ATTR_FRAGMENT,
  IMG_SRCSET_FRAGMENT,
  KEY_VALUE_PAIR_FRAGMENT,
  LINK_FRAGMENT } from './fragments';

export const CURRENT_EVENT_ID = gql`
  query currentEventId {
    eventId @client
  }
`;

export const HELP_LINK = gql`
  query getHelpLink {
    helpLinkUrl @client
  }
`;

export const IOS_PROMPT_SUPPRESS = gql`
  query getIosPromptSuppress {
    iosPromptSuppress @client
  }
`;

export const LOGIN = gql`
  query Login($path: String) {
    LoginScreen(path: $path) {
      currentEvents {
        eventClassification
        eventStartDateTimeISO
        id
        name
        typeId
      }
      logo {
        src
        width
        height
        srcSets{
          size
          src
        }
        srcSetsRaw
        altFormats {
          name
          src
          srcSets {
            size
            src
          }
          srcSetsRaw
        }
      }
      description
    }
  }
`;

export const SCORING_INFORMATION = gql`
  query GetAllScoringInformation ($id: ID) {
    ScoringInformation (id: $id) {
      status
      message
      leaderboardUrl {
        href
        body
      }
      helpLink {
        href
        body
      }
      divisions {
        ...divisionFragment
      }
      driveScoringOptions {
        ...keyValuePairFragment
      }
      chipScoringOptions {
        ...keyValuePairFragment
      }
      puttScoringOptions {
        ...keyValuePairFragment
      }
      eventInformation {
        ...eventFragment
      }
      eventLinks {
        ...linkFragment
      }
      eventParticipationRecords {
        ...eventParticipationRecordFragment
      }
      eventParticipationStatusOptions {
        ...keyValuePairFragment
      }
    }
  }
  ${CONTACT_INFO_FRAGMENT}
  ${DIVISION_FRAGMENT}
  ${EVENT_PARTICIPANT_RECORD_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${IMG_ALT_FRAGMENT}
  ${IMG_ATTR_FRAGMENT}
  ${IMG_SRCSET_FRAGMENT}
  ${KEY_VALUE_PAIR_FRAGMENT}
  ${LINK_FRAGMENT}
`;

export const PARTICIPANTS_IN_EVENT = gql`
  query GetEventParticipants ($id: ID) {
    ScoringInformation (id: $id) {
      eventParticipationRecords {
        division @client
        id
        overallTotal
        chipTotal
        driveTotal
        puttTotal
        participantDivisionId
        participantFirstName
        participantId
        participantLastName
        typeId
        startTime
        groupNumber
        starterNumber
        eventParticipationStatus
      }
    }
  }
`;

export const PARTICIPANT_RECORD_WITH_DIVISION = gql`
  query GetParticipantRecordAndDivision($id: String) {
    divisionParticipants @client
    originalParticipant (id: $id)
    participant(id: $id) {
      division @client
      scores @client
      chip1
      chip2
      chip3
      chipTotal
      drive1
      drive2
      drive3
      driveTotal
      id
      overallTotal
      participantDivisionId
      participantFirstName
      participantId
      participantImage(size: "square80") {
        ...imageAttributesFragment
      }
      participantLastName
      putt1
      putt2
      putt3
      puttTotal
      typeId
      startTime
      groupNumber
      starterNumber
      eventParticipationStatus
    }
  }
  ${IMG_ALT_FRAGMENT}
  ${IMG_ATTR_FRAGMENT}
  ${IMG_SRCSET_FRAGMENT}
`;

export const APP_STATUS = gql`
  query GetAppStatus {
    isOnline @client
    isUpdating @client
    localUpdates @client
    isErrored @client
    isLoggedIn @client
    errorMessage @client
  }
`;

export const DIVISION_PARTICIPANTS = gql`
  query GetDivisionParticipants {
    divisionParticipants @client
  }
`;

export const SCORING_OPTIONS = gql`
  query GetScoringOptions {
    chip @client
    putt @client
    drive @client
  }
`;

export const PARTICIPANT_RECORD_WITH_SCOREOPTIONS = gql`
  query GetParticipantRecordAndScoreOptions($id: String) {
    participant(id: $id) {
      division @client
      chip1
      chip2
      chip3
      chipTotal
      drive1
      drive2
      drive3
      driveTotal
      id
      overallTotal
      participantId
      participantLastName
      putt1
      putt2
      putt3
      puttTotal
    }
    chip @client
    putt @client
    drive @client
  }
`;

export const PARTICIPANT_STATUS_OPTIONS = gql`
  query GetStatusOptions {
    statusOptions @client
  }
`;

// commenting this for now but saving for reference. This used to be the way to send the EPRs with the mutation, but it included ALL Eprs.
// export const PARTICIPANT_INPUTS = gql`
//   query GetEventParticipantsForUpdate ($id: ID) {
//     ScoringInformation (id: $id) {
//       eventParticipationRecords {
//         chip1
//         chip2
//         chip3
//         chipTotal
//         drive1
//         drive2
//         drive3
//         driveTotal
//         putt1
//         putt2
//         putt3
//         puttTotal
//         id
//         overallTotal
//         participantId
//         eventParticipationStatus
//       }
//     }
//   }
// `;

// This query saves a snapshot of the original EPRs for delta comparison later
export const INITIAL_EPRS = gql`
  query GetInitialEPRs {
    intitialEPRs @client
  }
`;

// this query compiles a list of EPRs associated with the IDs in the above query return. The data retrieved for 
// each EPR matches the Participant Input which the mutation expects.
export const UPDATED_EPRS = gql`
  query GetUpdatedEprInputs {
    eprInputs @client
  }
`;