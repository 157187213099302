import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import { PARTICIPANT_STATUS_OPTIONS } from '../constants/queries';
import styles from './ParticipantStatus.module.scss';

export default function ParticipantStatus(props) {
  const [currentStatus, setCurrentStatus] = useState(props.currentStatus);
  const { isQuickView } = props;
  const { data } = useQuery(PARTICIPANT_STATUS_OPTIONS);
  const { statusOptions:options } = data;
  
  const updateStatus = (e) => {
    setCurrentStatus(e.currentTarget.value);
    props.onUpdate(e.currentTarget.value);
  }

  if (isQuickView) {
    return (
      <div className={`${styles.quickview} ${styles[currentStatus]}`}></div>
    )
  } else {
    return (
      <div className={`${styles.dropdown} ${styles[currentStatus]}`}>
        <select defaultValue={currentStatus} onChange={updateStatus}>
          {options.map(data => (
            <option key={data.key} value={data.value}>{data.key}</option>
          ))}
        </select>
      </div>
    )
  }
}
