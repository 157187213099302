/* eslint-disable no-unused-vars */
import React from 'react'
import { GET_EVENT_INFO } from '../constants/fragments';
import { CURRENT_EVENT_ID, APP_STATUS } from '../constants/queries';
import { convertISOTime, convertISODate } from '../constants/utils';
import { useApolloClient, useQuery } from '@apollo/client';
import fpoimg from '../assets/no-image.png';
import styles from './EventHeader.module.scss';

export default function EventHeader(props) {
  const client = useApolloClient();
  let eventClassification, name, image, time, date;
  eventClassification = name = time = 'na';
  const { data } = useQuery(CURRENT_EVENT_ID);
  const { data:appStatus } = useQuery(APP_STATUS);

  const fragment = client.readFragment({id:'Event:'+data.eventId, fragment: GET_EVENT_INFO});
  if (fragment !== null) {
    eventClassification = fragment.eventClassification;
    name = fragment.name;
    image = fragment.image;
    time = convertISOTime(fragment.eventStartDateTimeISO);
    date = convertISODate(fragment.eventStartDateTimeISO);
  }

  let imgsrc = image.src === null || image.src === 'USEFPO' || !appStatus.isOnline ? fpoimg : image.src;

  return (
    <div className={styles.wrapper}>
      <div className={styles.imagewrapper} style={{'backgroundImage': `url(${imgsrc})`}}></div>
      <div className={styles.eventDetails}>
        <span>{eventClassification}</span>
        <h2 className={styles.h2}>{name}</h2>
        <span>{date} {time}</span>
      </div>
    </div>
  )
}
