import React from 'react'
import { Link, useHistory } from 'react-router-dom';

export default function ParticipantListLink(props) {
  const history = useHistory();

  const gotoParticipant = (e) => {
    e.preventDefault();
    document.documentElement.style.setProperty('--participantTransitionTime', '150ms');
    document.documentElement.style.setProperty('--participantIn', 'translateX(0%)');
    document.documentElement.style.setProperty('--participantOut', 'translateX(0%)');
    document.documentElement.style.setProperty('--participantInOpacity', '1');
    document.documentElement.style.setProperty('--participantOutOpacity', '0');
    history.push(props.path)
  }

  return (
    <Link onClick={gotoParticipant} to={props.path}>
      {props.children}
    </Link>
  )
}
