import React, { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { checkOnlineStatus } from '../containers/UpdateUtilsContainer';
import HelpLink from '../components/HelpLink';
import styles from './SkillHeader.module.scss';

export default function SkillHeader(props) {
  const history = useHistory();
  const mountedRef = useRef(true)

  const onBack = async (e) => {
    e.preventDefault();
    checkOnlineStatus().then(response => {
      if (mountedRef.current) {
        if (props.backpath) {
          history.push(props.backpath);
        } else {
          history.goBack();
        }
      }
    });
  }
  
  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  // const onBack = () => {
  //   if (props.backpath) {
  //     history.push(props.backpath);
  //   } else {
  //     history.goBack();
  //   }
  // }

  const divisionTitle = () => {
    if (props.division) {
      return (
        <h3>{props.division}</h3>
      )
    }
  }

  const backButton = () => {
    if (!props.hideBack) {
      return (
        <button className={`${styles.backbutton} not-button`} onClick={onBack}></button>
      )
    }
  }

  return (
    <div className={props.isParticipant ? `${styles.participantheader} smallIcons` : styles.header}>
      {backButton()}
      <div className={`skillicon ${props.skill}`}></div>
      <h2>{props.label}</h2>
      {props.showHelp ? <HelpLink pullright={true} /> : null}
      {divisionTitle()}
    </div>
  )
}
