import React from 'react'
import { useQuery } from '@apollo/client';
import { HELP_LINK } from '../constants/queries';

export default function HelpLink(props) {
  const { data } = useQuery(HELP_LINK);

  return (
    <>
      <a className={`needhelp-link ${props.pullright ? 'pull-right' : ''}`} rel='noopener noreferrer' target='_blank' href={data.helpLinkUrl}>Need help?</a>
    </>
  )
}
