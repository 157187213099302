import { InMemoryCache, makeVar } from '@apollo/client'
import { DIVISION_NAME } from './constants/fragments';

export const isAppOnline = makeVar(true);

export default function createCache() {
  const cache = new InMemoryCache({
    typePolicies: {
      ScoringInformation: {
        keyFields: [],
        fields: {
          helpLink: {
            read(helpLink, { cache, readField }) {
              if (helpLink === null) {
                return {
                  'body': 'Need help?',
                  'href': readField('leaderboardUrl').href
                }
              } else {
                return helpLink;
              }
            }
          },
          eventInformation : {
            fields: {
              contactInformation: {
                read(contactInformation) {
                  if (contactInformation === null) {
                    return {
                      'emailAddress': 'NA',
                      'name': 'NA',
                      'phoneNumber': 'NA',
                      'id':'FPO-Contact',
                      'typeId':'FPO'
                    }
                  } else {
                    return contactInformation;
                  }
                }
              }
            }
          }
        }
      },
      EventParticipationRecord: {
        fields: {
          division: {
            read(_, { cache, readField }) {
              if (readField('participantDivisionId') === null) return 'No Division';
              let { name } = cache.readFragment({id:'Division:'+readField('participantDivisionId'), fragment: DIVISION_NAME});
              return name;
            }
          },
          scores: {
            read(_, { cache, readField }) {
              return {
                'drive1': readField('drive1'),
                'drive2': readField('drive2'),
                'drive3': readField('drive3'),
                'driveTotal': readField('driveTotal'),
                'chip1': readField('chip1'),
                'chip2': readField('chip2'),
                'chip3': readField('chip3'), 
                'chipTotal': readField('chipTotal'),
                'putt1': readField('putt1'),
                'putt2': readField('putt2'), 
                'putt3': readField('putt3'), 
                'puttTotal': readField('puttTotal'),
                'overallTotal': readField('driveTotal') + readField('chipTotal') + readField('puttTotal')
              }
            }
          }
        }
      },
      Event: {
        fields: {
          contactInformation: {
            read(contactInformation) {
              if (contactInformation === null) {
                return {
                  'emailAddress': 'No email available',
                  'name': 'Not Available',
                  'phoneNumber': '-',
                  'id':'FPO-Contact',
                  'typeId':'FPO',
                  '__typename': 'Contact'
                }
              } else {
                return contactInformation;
              }
            }
          },
          image: {
            read(image) {
              if(image === null) {
                return {
                  'src': '/assets/no-image.png',
                  'width': '80',
                  'height': '80'
                }
              } else {
                return image;
              }
            }
          }
        }
      },
      Query: {
        fields: {
          participant(_, { args, toReference }) {
            return toReference({
              __typename: 'EventParticipationRecord',
              id: args.id,
            });
          },
          isLoggedIn : {
            read(isLoggedIn = false) {
              if(isLoggedIn === undefined) return false;
              return isLoggedIn;
            }
          },
          errorMessage : {
            read(errorMessage = 'You must have internet connection to load the initial state of the App and have logged in with valid credentials.') {
              return errorMessage;
            }
          },
          localUpdates: {
            read(localUpdates = false) {
              if (localUpdates === undefined) return false;
              return localUpdates;
            }
          },
          isOnline: {
            read(isOnline) {
              let b = isAppOnline();
              if (b === undefined) return false
              return b;
            }
          },
          isUpdating: {
            read(isUpdating = false) {
              if (isUpdating === undefined) return false
              return isUpdating;
            }
          },
          isErrored: {
            read(isErrored = false) {
              if (isErrored === undefined) return false
              return isErrored;
            }
          },
          iosPromptSuppress: {
            read(iosPromptSuppress = false) {
              if (iosPromptSuppress === undefined) return false
              return iosPromptSuppress;
            }
          },
          divisionParticipants: {
            read(divisionParticipants) {
              return divisionParticipants;
            }
          },
          originalParticipant(_, { args, readField }) {
            return readField('intitialEPRs').find(p => p.id === args.id)
          },
          eprInputs: {
            read(eprInputs = []) {
              return eprInputs
            }
          },
          eventId: {
            read(eventId = '0') {
              return eventId
            }
          }
        }
      }
    }
  })
  return cache;
}