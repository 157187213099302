import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'

import Splash from './views/Splash';
import ViewContainer from './containers/ViewContainer';

export default function App({ client, loading }) {

  if (loading) {
    return (
      <div className="loading">
        <Splash />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
          <ViewContainer />
      </ApolloProvider>
    </BrowserRouter>
  )
}