import { gql } from '@apollo/client';
import { CONTACT_INFO_FRAGMENT, 
    DIVISION_FRAGMENT, 
    EVENT_FRAGMENT, 
    IMG_ALT_FRAGMENT, 
    IMG_ATTR_FRAGMENT,
    IMG_SRCSET_FRAGMENT,
    KEY_VALUE_PAIR_FRAGMENT,
    LINK_FRAGMENT } from './fragments';

export const LOGIN_MUTATION = gql`
    mutation LoginScreenMutation ($path: String, $loginInput: LoginInput) {
        LoginScreenMutation (path: $path, loginInput: $loginInput) {
            status
            message
            result
        }
    }
`;

export const UPDATE_SCORING_INFORMATION = gql`
  mutation ScoringInformationMutation ($id: ID, $scoringInformation: ScoringInformationInput) {
    ScoringInformationMutation (
        id: $id,
        scoringInformation: $scoringInformation) {
        status
        message
        result {
            ...scoringInformationFragment
        }
    }
  }
  fragment scoringInformationFragment on ScoringInformation {
    chipScoringOptions {
        ...keyValuePairFragment
    }
    divisions {
        ...divisionFragment
    }
    driveScoringOptions {
        ...keyValuePairFragment
    }
    eventInformation {
        ...eventFragment
    }
    eventLinks {
        ...linkFragment
    }
    eventParticipationRecords {
        ...eventParticipationRecordMutationFragment
    }
    eventParticipationStatusOptions {
        ...keyValuePairFragment
    }
    helpLink {
        ...linkFragment
    }
    leaderboardUrl {
        ...linkFragment
    }
    puttScoringOptions {
        ...keyValuePairFragment
    }
  }

  ${CONTACT_INFO_FRAGMENT}
  ${DIVISION_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${IMG_ALT_FRAGMENT}
  ${IMG_ATTR_FRAGMENT}
  ${IMG_SRCSET_FRAGMENT}
  ${KEY_VALUE_PAIR_FRAGMENT}
  ${LINK_FRAGMENT}

  fragment eventParticipationRecordMutationFragment on EventParticipationRecord {
    chip1
    chip2
    chip3
    chipTotal
    drive1
    drive2
    drive3
    driveTotal
    eventParticipationStatus
    groupNumber
    id
    starterNumber
    overallTotal
    participantFirstName
    participantId
    participantImage(size: "square80") {
        ...imageAttributesFragment
    }
    participantLastName
    putt1
    putt2
    putt3
    puttTotal
    startTime
    typeId
  }
`;