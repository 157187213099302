import React, { useState, useEffect, useCallback } from 'react'
import styles from './ScoreOptionSelect.module.scss';

export default function ScoreOptionSelect(props) {
  const { options } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDescription, setSelectedOptionDescription] = useState(null);
  const [showDD, setShowDD] = useState(false);

  const getSelectedOptionDescription = useCallback(
    ($value) => {
      if ($value === null || $value === '-' || $value === '-1' || $value === -1) return '';
      let index = options.findIndex(obj => Number(obj.value) === Number($value));
      return options[index].key;
    }, 
    [options]
  );

  useEffect(() => {
    // set initial selected value on mount.
    setSelectedOption(props.initialScore);
    setSelectedOptionDescription(getSelectedOptionDescription(props.initialScore));
  }, [props.initialScore, getSelectedOptionDescription])

  const updateScore = (e) => {
    setSelectedOption(e.currentTarget.value);
    props.onUpdate(e.currentTarget.value);
    setSelectedOptionDescription(getSelectedOptionDescription(e.currentTarget.value))
    setShowDD(!showDD);
  }
  
  return (
    <div className={styles.scoreoptions}>
      <select value={selectedOption !== null ? selectedOption : '-1'} onBlur={() => setShowDD(false)} onClick={() => setShowDD(true)} className={showDD ? styles.selectvisible : styles.selecthidden} onChange={updateScore}>
        <option key={`attempt1novalue`} value='-1'>No Score</option>
        {options.map(data => (
          <option key={`attempt1${data.key}`} value={data.value}>{data.key} - {data.value} pts</option>
        ))}
      </select>
      <span className={styles.optiondescription}>{selectedOptionDescription}</span>
      <input className={styles.input} onClick={() => setShowDD(true)} readOnly value={(selectedOption === null || selectedOption === '-1' || selectedOption === -1) ? '-' : selectedOption} type='text' />
    </div>
  )
}
