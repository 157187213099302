import React from 'react'
import styles from './ToggleButton.module.scss';

export default function ToggleButton(props) {
  if (props.simpleToggle) {
    return (
      <>
        <input className='ToggleInput' id={props.id} defaultChecked={props.isChecked? 'checked' : ''} onClick={props.clickHandler} type="checkbox" />
        <label htmlFor={props.id} className="sr-only">{props.label}</label>
      </>
    )
  } else {
    return (
      <div className={styles.toggler}>
        <label className={styles.label}>
          <span className={!props.isChecked? styles.active : styles.notactive}>{props.labelone}</span>
          <input disabled={props.disabled} className={`ToggleInput ${styles.switch}`} id={props.id} defaultChecked={props.isChecked? 'checked' : ''} onClick={props.clickHandler} type="checkbox" />
          <span className={props.isChecked? styles.active : styles.notactive}>{props.labeltwo}</span>
        </label>
      </div>
    )  
  }
}