import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './ParticipantButton.module.scss';

export default function ParticipantButton(props) {
  const history = useHistory();
  if (props.participantName === undefined) return (
    <button disabled='disabled' className={styles.link}></button>
  );
  
  const goToParticipant = () => {
    document.documentElement.style.setProperty('--participantTransitionTime', '400ms');
    document.documentElement.style.setProperty('--participantActive', 'translateX(0%)');
    document.documentElement.style.setProperty('--participantInOpacity', '1');
    document.documentElement.style.setProperty('--participantOutOpacity', '1');
    if (props.direction === '0') {
      // direction is left.
      document.documentElement.style.setProperty('--participantIn', 'translateX(100%)');
      document.documentElement.style.setProperty('--participantOut', 'translateX(-100%)');
    } else {
      document.documentElement.style.setProperty('--participantIn', 'translateX(-100%)');
      document.documentElement.style.setProperty('--participantOut', 'translateX(100%)');
    }
    history.push(props.path);
  }

  return (
    <button disabled={props.disabled} type='button' onClick={goToParticipant} className={styles.link}>
      <span className={styles.label}>{props.label}</span>
      <span className={styles.name}>{props.participantName}</span>
      <span className={styles.time}>{props.startTime || 'NA'}</span>
    </button>
  )
}
