import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom'
import { APP_STATUS, SCORING_INFORMATION, CURRENT_EVENT_ID, SCORING_OPTIONS, DIVISION_PARTICIPANTS, PARTICIPANT_STATUS_OPTIONS, HELP_LINK, INITIAL_EPRS } from '../constants/queries';
import styles from './Home.module.scss';
import Header from '../components/Header';
import HelpLink from '../components/HelpLink';
import Loading from './Loading';
import LoadingError from './LoadingError';
import LoggedOut from './LoggedOut';
import EventHeader from '../components/EventHeader';
import ModalPrompt from '../components/ModalPrompt';
import ErrorBoundary from '../containers/ErrorBoundary';
import checkinIcon from '../assets/icons/icon-checkmark.svg';
import leaderboardIcon from '../assets/icons/icon-leaderboard.svg';
import questionIcon from '../assets/icons/icon-question.svg';
import gearIcon from '../assets/icons/icon-gear.svg';

export default function Home() {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const client = useApolloClient();
  const { data:appStatus } = useQuery(APP_STATUS);
  const { data } = useQuery(CURRENT_EVENT_ID);
  const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  let eventId = data.eventId;
  
  const onLogoutFormSubmit = (e) => {
    e.preventDefault();
    if (appStatus.localUpdates) {
      setShowModal(true);
    } else {
      // need to perform mutation here to log the user out/discard auth token/whatever
      onLogout();
    }
  }

  const onLogout = () => {
    client.writeQuery({ query: APP_STATUS, data: {isLoggedIn: false} })
    client.resetStore();
    localStorage.removeItem('token');
    history.push('/');
  }

  const onCancelModal = () => {
    setShowModal(false);
  }

  const { loading, error, data:scoringdata } = useQuery(
    SCORING_INFORMATION, {
      variables: {
        'id': eventId
      },
      fetchPolicy: appStatus.isOnline ? 'cache-and-network' : 'cache-only',
      errorPolicy: 'all',
      onError(cache, {data: returnData}) {
        client.writeQuery({ query: APP_STATUS, data: {isErrored: true} });
        if (returnData.ScoringInformation.message) {
          client.writeQuery({ query: APP_STATUS, data: {errorMessage: returnData.ScoringInformation.message} });
        }
        history.push('/error');
      },
      onCompleted({ScoringInformation}) {
        // only save these pristine EPRs if they come from the server
        if (appStatus.isOnline) client.writeQuery({ query: INITIAL_EPRS, data: {intitialEPRs: ScoringInformation.eventParticipationRecords} });
      }
    }
  );

  if (loading) { 
    return (
      <div>
        <Loading />
      </div>
    );
  }
  
  if (!localStorage.getItem('token')) {
    return (
      <div>
        <LoggedOut />
      </div>
    )
  }
  
  if (error || !scoringdata.ScoringInformation) {  
    error.graphQLErrors.forEach(({message}) => { console.log(message)});
    return (
      <div>
        <LoadingError />
      </div>
    )
  }

  // Some cache writing for rest of app to easily access parts of data.
  if ( scoringdata.ScoringInformation ) {
    client.writeQuery({
      query: SCORING_OPTIONS,
      data: {
        chip: [...scoringdata.ScoringInformation.chipScoringOptions],
        drive: [...scoringdata.ScoringInformation.driveScoringOptions],
        putt: [...scoringdata.ScoringInformation.puttScoringOptions],
      },
    });

    client.writeQuery({
      query: DIVISION_PARTICIPANTS,
      data: {
        divisionParticipants: [...scoringdata.ScoringInformation.eventParticipationRecords],
      },
    });

    client.writeQuery({
      query: PARTICIPANT_STATUS_OPTIONS,
      data: {
        statusOptions: [...scoringdata.ScoringInformation.eventParticipationStatusOptions],
      },
    });

    client.writeQuery({
      query: HELP_LINK,
      data: {
        helpLinkUrl: scoringdata.ScoringInformation.helpLink.href
      }
    });
  }
  const { emailAddress:contactEmailAddress, name:contactName, phoneNumber:contactPhoneNumber } = scoringdata.ScoringInformation.eventInformation.contactInformation;
  const { leaderboardUrl, eventLinks } = scoringdata.ScoringInformation;
  
  const leaderboardLink = () => {
    if (leaderboardUrl.href !== null) {
      return (
        <li><a href={leaderboardUrl.href} rel='noopener noreferrer' target='_blank'>Leaderboard<img className={styles.icon} src={leaderboardIcon} alt='icon' /></a></li>
      )
    }
  }

  return (
    <div>
      <Header />
      <div className='content-wrapper content-top'>
        <ErrorBoundary>
          <EventHeader eventId={eventId} />
        </ErrorBoundary>
        <div className={styles.uipanel}>
          <p className={styles.instructions}>Select a skill below to begin scoring.<HelpLink /></p>
          <div className={styles.skillbuttons}>
            <Link to='/skill/drive' className={styles.card}><div className='skillicon drive'></div>Drive</Link>
            <Link to='/skill/chip' className={styles.card}><div className='skillicon chip'></div>Chip</Link>
            <Link to='/skill/putt' className={styles.card}><div className='skillicon putt'></div>Putt</Link>
          </div>
          <ul className={styles.linklist}>
            <li><Link to='/roster'>Event Roster | Check in<img className={styles.icon} src={checkinIcon} alt='icon' /></Link></li>
            {leaderboardLink()}
            {eventLinks.map(link => <li key={link.href}><a href={link.href} rel='noopener noreferrer' target='_blank' dangerouslySetInnerHTML={{__html:`${link.body} <img class=${styles.icon} src=${questionIcon} alt='icon' />`}}></a></li>)}
            <li><Link to='/about'>About this App<img className={styles.icon} src={gearIcon} alt='icon' /></Link></li>
            <li>Contact Course Admin
              <div className={`${styles.card} ${styles.contactCard}`}>
                <strong>{contactName}</strong><br />
                {contactEmailAddress.match(mailRegex) ? <a href={`mailto:${contactEmailAddress}`}>{contactEmailAddress}</a> : 'No email available'}<br />
                {contactPhoneNumber !== '-' ? <a href={`tel:${contactPhoneNumber}`}>{contactPhoneNumber}</a> : '-'}
              </div>
            </li>
          </ul>
          <form onSubmit={onLogoutFormSubmit} className={styles.form}>
            <button>Logout</button>
          </form>
        </div>
      </div>
      <ModalPrompt show={showModal} onLogout={onLogout} onCancel={onCancelModal} />
    </div>
  )
}
