import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { convertISOTime } from '../constants/utils';
import { PARTICIPANTS_IN_EVENT, CURRENT_EVENT_ID } from '../constants/queries';
import ParticipantStatus from '../components/ParticipantStatus';
import ParticipantListLink from '../components/ParticipantListLink';
import sharedstyles from './Division.module.scss';
import styles from './Roster.module.scss';

export default function Roster(props) {
  let fullParticipantList = [];
  const history = useHistory();
  const { data } = useQuery(CURRENT_EVENT_ID);

  const { data:eventdata } = useQuery(
    PARTICIPANTS_IN_EVENT,
    {
      variables: {
        id: data.eventId,
      },
      fetchPolicy: 'cache-only'
    }
  );
  
  const onBack = () => {
    if (props.backpath) {
      history.push(props.backpath);
    } else {
      history.goBack();
    }
  }

  if (eventdata !== null) {
    fullParticipantList = eventdata.ScoringInformation.eventParticipationRecords;
    // sort alphabetically to start with
    fullParticipantList = [...fullParticipantList].sort((a, b) => {
      let nameA = a.participantLastName.toUpperCase(); // ignore upper and lowercase
      let nameB = b.participantLastName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0;  // names must be equal
    });
  }

  const filterParticipantList = (e) => {
    let query = e.currentTarget.value;
    updateParticipantList([...fullParticipantList].filter((participant) => {
      let name = participant.participantFirstName + participant.participantLastName;
      return name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      })
    )
  }

  const [currentParticipantList, updateParticipantList] = useState([...fullParticipantList]);
  
  return (
    <div className='content-wrapper content-top'>
      <div className={styles.header}>
        <div className={styles.headerinner}>
          <button className={`${styles.backbutton} not-button`} onClick={onBack}></button>
          <input placeholder='Filter by name' type='text' onChange={filterParticipantList} />
        </div>
      </div>
      <ul className={`${sharedstyles.participantlist} ${styles.rosterList}`}>
        <li>
          <ul className={sharedstyles.participantHeaders}>
            <li></li>
            <li>Name</li>
            <li>Start</li>
            <li>Score</li>
          </ul>
        </li>
      </ul>
      <TransitionGroup component='ul' className={`${sharedstyles.participantlist} ${styles.rosterList}`}>
        {currentParticipantList.map((participant) => (
          <CSSTransition key={participant.id} timeout={300} classNames='roster-list' component={null}>
          <li key={participant.id}>
              <ParticipantListLink path={`/participant/all/${participant.id}`}>
                <ul className={sharedstyles.participantResults}>
                  <li><ParticipantStatus currentStatus={participant.eventParticipationStatus} isQuickView={true} /></li>
                  <li>
                    <span className={styles.participantName}>{participant.participantFirstName} <strong>{participant.participantLastName}</strong></span>
                    <span className={styles.divisionTitle}>{participant.division}</span>
                  </li>
                  <li>{convertISOTime(participant.startTime) || 'NA'}</li>
                  <li>{participant.overallTotal || '-'}</li>
                </ul>
              </ParticipantListLink>
          </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}
